var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CommonLayout } from '../common/layout';
import { Alert, Button, Card, CardContent, Grid, Link, Typography, } from '@mui/material';
import useSSOLanding from './useSSOLanding';
import { styled } from '@mui/material/styles';
var LoadingImage = styled('img')({
    maxHeight: '50px',
    maxWidth: '100%',
    width: '100%',
});
var BannerCard = styled(Card)({
    background: '#f6f6f6',
    color: '#ff0000',
    margin: '5px',
    textAlign: 'center',
    wordWrap: 'break-word',
    '& p': {
        margin: 0,
    },
});
var SSOLanding = function () {
    var _a;
    var _b = useSSOLanding(), isError = _b.isError, authFlow = _b.authFlow, state = _b.state, loadingImgUrl = _b.loadingImgUrl, onContinueClick = _b.onContinueClick;
    return (_jsxs(CommonLayout, __assign({ isLanding: true }, { children: [isError && (_jsx(Grid, __assign({ item: true, xs: true, sx: { marginTop: '15px' } }, { children: _jsx(Alert, __assign({ severity: 'error' }, { children: "Unable to load SSO information, please try again later" })) }))), !authFlow && !isError && (_jsx(Grid, __assign({ item: true, xs: true, sx: { marginTop: '15px' } }, { children: _jsx(LoadingImage, { src: loadingImgUrl, alt: 'Loading…' }) }))), authFlow && !isError && (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: true, sx: { marginTop: '15px' } }, { children: _jsxs(Typography, { children: ["Please sign-in using your ", (_a = authFlow === null || authFlow === void 0 ? void 0 : authFlow.name) !== null && _a !== void 0 ? _a : 'SSO', " account."] }) })), _jsx(Grid, __assign({ item: true, xs: true, sx: { marginTop: '50px' } }, { children: _jsx(Button, __assign({ color: "primary", disableElevation: true, fullWidth: true, name: "ssoContinue", onClick: onContinueClick, variant: "contained" }, { children: "Continue" })) })), state.fallBackClientId && (_jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(Link, __assign({ href: "/auth/login?clientId=".concat(state.fallBackClientId), variant: "body2", id: "goToLoginLink" }, { children: "Sign-in with a different account" })) }))), state.landing.notificationBannerHtml &&
                        state.landing.notificationBannerHtml.trim() !== '' && (_jsx(Grid, __assign({ item: true, xs: true }, { children: _jsx(BannerCard, __assign({ variant: "outlined" }, { children: _jsx(CardContent, { children: _jsx(Typography, { variant: "body2", dangerouslySetInnerHTML: {
                                        __html: state.landing.notificationBannerHtml,
                                    } }) }) })) })))] }))] })));
};
export default SSOLanding;
